import React, { useMemo } from 'react'
import { graphql } from 'gatsby'
import { Container, Heading, Box, SimpleGrid } from '@chakra-ui/react'

import Layout from '../components/layout'
import PostPreview from '../components/Blog/PostPreview'
import SEO from '../components/seo'
import Pagination from '../components/Blog/Pagination'

const blogPageMeta = {
  title: 'Article',
  description: "onkbear's articles.",
}

const BlogPage = ({ data }) => {
  // Merge blog and qiita posts.
  const posts = useMemo(() => {
    return [
      ...data.allContentfulBlogPost.edges,
      ...data.allQiitaPost.edges,
    ].sort((a, b) => {
      const aDate = new Date(a.node.fields.date)
      const bDate = new Date(b.node.fields.date)

      if (aDate < bDate) return 1
      if (aDate > bDate) return -1
      return 0
    })
  }, [data])

  return (
    <Layout>
      <SEO title={blogPageMeta.title} description={blogPageMeta.description} />
      <Container maxW="3xl">
        <Heading
          as="h1"
          sx={{
            marginTop: '2em',
            marginBottom: '2em',
            textAlign: 'center',
          }}
        >
          Article
        </Heading>

        <SimpleGrid columns={[1, null, 2]} spacing={4}>
          {posts &&
            posts.length > 0 &&
            posts.map((post, idx) => {
              const isLastPost = idx === post.length - 1

              return (
                <Box key={idx}>
                  <PostPreview {...post.node} />
                </Box>
              )
            })}
        </SimpleGrid>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query blogListQuery($skip: Int!, $limit: Int!) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    allContentfulBlogPost(
      filter: { content: { content: { ne: null } }, node_locale: { eq: "ja" } }
      sort: { fields: [firstPublishedAt], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          fields {
            slug
            title
            excerpt
            date
            thumbnail
            type
          }
          id
          title
          firstPublishedAt
          slug
          content {
            childMdx {
              excerpt(pruneLength: 60)
            }
          }
          headerImage {
            title
            file {
              url
            }
            fluid {
              ...GatsbyContentfulFluid
            }
            gatsbyImageData
          }
        }
      }
    }
    allQiitaPost(sort: { fields: [created_at], order: DESC }) {
      edges {
        node {
          title
          url
          created_at
          fields {
            slug
            title
            excerpt
            date
            thumbnail
            type
          }
        }
      }
    }
  }
`

export default BlogPage
