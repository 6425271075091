import React from 'react'
import { format } from 'date-fns'
import { Box, Heading, Text } from '@chakra-ui/react'
import Image from '../Common/Image'
import QiitaImage from './QiitaImage'
import { StaticImage, GatsbyImage } from "gatsby-plugin-image";
import { Link } from 'gatsby'

const Title = ({ title, formattedDate, postUrl }) => {
  return (
    <Box>
      <Box as={Link} to={postUrl}>
        <Heading mt="0.5em" size="md">
          {title}
        </Heading>
      </Box>
      <Box>{formattedDate}</Box>
    </Box>
  )
}

const PostPreview = (props) => {
  const { slug, title, excerpt, date, type } = props.fields

  const formattedDate = format(new Date(date), 'dd MMM, yyyy')

  if (type === 'ContentfulBlogPost') {
    const { childMdx: { excerpt = '' } = {} } = props.content || {}

    return (
      <Box
        boxShadow="0 0 0 1px rgba(0, 0, 0, 0.1), 0 0 0 3px rgba(0, 0, 0, 0.02)"
        borderRadius="md"
      >
        <Link to={slug}>
          {props.headerImage && <Image image={props.headerImage} alt={title} />}
        </Link>
        <Box p={4}>
          <Title title={title} formattedDate={formattedDate} postUrl={slug} />
          <Text noOfLines={2} overflowWrap="anywhere">
            {excerpt}
          </Text>
        </Box>
      </Box>
    )
  } else if (type === 'QiitaPost') {
    return (
      <Box
        boxShadow="0 0 0 1px rgba(0, 0, 0, 0.1), 0 0 0 3px rgba(0, 0, 0, 0.02)"
        borderRadius="md"
      >
        <a href={slug}>
          <QiitaImage />
        </a>
        <Box p={4}>
          <Title title={title} formattedDate={formattedDate} postUrl={slug} />
          <Text noOfLines={2} overflowWrap="anywhere">
            {excerpt}
          </Text>
        </Box>
      </Box>
    )
  }

  return <Box>Error</Box>
}

export default PostPreview
