import React from 'react'
import { StaticImage } from "gatsby-plugin-image";

const QiitaImage = () => {
  return (
    <StaticImage src="../../images/qiita-logo.png" alt="Qiita" />
  )
}

export default QiitaImage
